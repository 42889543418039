.img{
    display: flex;
    align-items: center;
}
.cellImg{
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 20px;
}
.cellAction{
    display: flex;
    align-items: center;
    gap: 15px;
}
.viewButton{
     padding: 2px 5px;
     border-radius: 5px;
     color: grey;  
     border: 1px dotted grey;
}
.editButton{
    padding: 2px 5px;
    border-radius: 5px;
    color: lightgreen;  
    border: 1px dotted lightgreen;
}